//todo: need to implement instances

const state = {
  selected: ref(new Map()), //contains ids of selected items
  lastSelected: ref(null),
  candidates: ref([]), //contains whole objects of candidates (not just ids)
  isShiftDown: ref(false),
  onSelectionChangeHandlers: [],
  onSelectionClearHandlers: []
};

let initializedCount = 0;

const count = computed(() => state.selected.value.size);
const hasAny = computed(() => count.value > 0);
const selected = computed(() => Array.from(state.selected.value.values()));

const clear = () => {
  state.selected.value.clear();
  state.lastSelected.value = null;
  state.candidates.value = [];
  state.isShiftDown.value = false;

  state.onSelectionClearHandlers.forEach(h => h());
};

const onKeyDown = e => {
  if (e.key === 'Shift') {
    state.isShiftDown.value = true;
  }
};

const onKeyUp = e => {
  if (e.key === 'Shift') {
    state.isShiftDown.value = false;
  }

  if (e.key === 'Escape') {
    clear();
  }
};

watch(state.lastSelected, newVal => {
  if (!newVal) {
    state.candidates.value = [];
  }
});

export function useSelection() {

  const initialize = () => {
    if (!initializedCount) {
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
    }
    initializedCount++;
  };
  const uninitialize = () => {
    if (initializedCount) {
      initializedCount--;
    }

    //note: this is not exactly an `else`, so don't put it there if you feel that urge
    if (!initializedCount) {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
      clear();
    }
  };

  onMounted(() => initialize());
  onUnmounted(() => uninitialize());

  return {
    update({select = [], unselect = [], isDisabledChecker}) {
      let filteredSelect = select;

      if (select.length) {
        filteredSelect = filteredSelect.filter(item => !(isDisabledChecker && isDisabledChecker(item)));

        //note: resist the temptation to put both checks (for filtering out disabled and for counting the additions) in a single loop - results in a subtle bug with setting the starting point for shift selection
        const additions = filteredSelect.filter(item => !this.has(item));
        const isWithinSelectionLimit = additions.length + state.selected.value.size <= FILE_MAX_SELECTION;

        if (!isWithinSelectionLimit) {
          filteredSelect = additions.slice(0, FILE_MAX_SELECTION - state.selected.value.size);

          useWarningToast().add({
            id: 'selection-limit',
            title: 'Selection Limit Reached',
            description: `You have reached the max selection of ${FILE_MAX_SELECTION} items.`,
            timeout: 5000
          });
        }
      }

      filteredSelect.forEach(item => state.selected.value.set(item.id, item));
      unselect.forEach(item => state.selected.value.delete(item.id));

      state.lastSelected.value = filteredSelect[filteredSelect.length - 1]?.id;
      state.onSelectionChangeHandlers.forEach(h => h({select: filteredSelect, unselect}));
    },
    toggle({item, isDisabledChecker}) {
      if (state.isShiftDown.value && state.candidates.value?.length) {
        //handle shift select/deselect

        const isSelect = state.candidates.value.some(candidate => !this.has(candidate));

        if (isSelect) {
          this.update({select: Array.from(state.candidates.value), isDisabledChecker})
        } else {
          this.update({unselect: Array.from(state.candidates.value), isDisabledChecker})
          state.candidates.value = [];
        }

      } else {
        if (this.has(item)) {
          this.update({unselect: [item], isDisabledChecker});
        } else {
          this.update({select: [item], isDisabledChecker});
        }
      }
    },
    clear,
    has(item) {
      return state.selected.value.has(item.id);
    },
    hasAny,
    count,
    selected,

    updateSelectionCandidates({item, collection}) {
      if (!state.lastSelected.value) {
        return;
      }

      const startIndex = collection.findIndex(cItem => cItem.id === state.lastSelected.value);
      const endIndex = collection.findIndex(cItem => cItem.id === item.id);
      const candidatesStart = Math.min(startIndex, endIndex);
      const candidatesEnd = Math.max(startIndex, endIndex);

      state.candidates.value = collection.slice(candidatesStart, candidatesEnd + 1);

      if (startIndex > endIndex) {
        state.candidates.value.reverse();
      }
    },
    isCandidate(item) {
      return state.isShiftDown.value && (state.candidates.value?.indexOf(item) > -1);
    },

    addSelectionChangeHandler(handler) {
      state.onSelectionChangeHandlers.push(handler);
    },
    removeSelectionChangeHandler(handler) {
      const index = state.onSelectionChangeHandlers.findIndex(h => h === handler);

      if (index > -1) {
        state.onSelectionChangeHandlers.splice(index, 1);
      }
    },
    addSelectionClearHandler(handler) {
      state.onSelectionClearHandlers.push(handler);
    },
    removeSelectionClearHandler(handler) {
      const index = state.onSelectionClearHandlers.findIndex(h => h === handler);

      if (index > -1) {
        state.onSelectionClearHandlers.splice(index, 1);
      }
    }
  };
}
